
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sprinkles';
  src: url('./fonts/sprinkles_3/Sprinkles.otf') format('opentype'), /* IE9 Compat Modes */ 
       url('./fonts/sprinkles_3/Sprinkles.ttf')  format('truetype'),; /* Safari, Android, iOS */
  font-size: 70.4px;

}

      /* //add poppins font */
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

@font-face{
  font-family: 'Bright';
  src: url('./fonts/bright-font/Bright-gxdOP.otf') format('opentype');
}

body{
  font-family: 'Poppins', sans-serif;
}


#fullBlogPost ul,ol > li{
  padding-top: 10px;
  padding-bottom: 10px;
}

#fullBlogPost ul{
  list-style-type: disc;
}

.wp-block-heading{
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
}
